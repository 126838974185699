@font-face {
  font-family: Redaction-35;
  src: url(./fonts/Redaction35-BWXjw.otf);
}

@font-face {
  font-family: Beton-Bold;
  src: url(./fonts/BetonP-ExtBol.otf);
}

@font-face {
  font-family: Beton-Open-Regular;
  src: url(./fonts/BetonOpen-Regular.otf);
}

@font-face {
  font-family: Junicode;
  src: url(./fonts/Junicode.ttf);
}

@font-face {
  font-family: Junicode-Italic;
  src: url(./fonts/Junicode-Italic.ttf);
}

@font-face {
  font-family: Junicode-Bold;
  src: url(./fonts/Junicode-Bold.ttf);
}

@font-face {
  font-family: Junicode-Bold-Italic;
  src: url(./fonts/Junicode-BoldItalic.ttf);
}

.container {
  width: 100vw;
}

#italicize {
  font-family: Junicode-Italic;
}

body {
  font-family: Junicode;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url(http://www.javascriptkit.com/dhtmltutors/cursor-hand.gif), auto;
}

#sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 20px;
}

#issue-number {
  margin: 10px;
}

#magazine-title-link {
  text-decoration: none;
  color: black;
}

#fixed-header {
  position: fixed;
  width: 100%;
  background-color: white;
  margin-top: -20px;
  padding: 20px;
}

#footer {
  width: 30px;
}

#about-page {
  padding: 20px;
  background-color: #fff44c;
  height: 100%;
}

#submissions-page {
  padding: 20px;
  background-color: #fff44c;

}

#issue-number-and-date {
}

#pages {
  z-index: 1000;
}

.page-title {
  font-family: Beton-Bold;
  font-size: 3em;
}

#about-text, #submissions-text {
  width: 50%;
  margin: 0 auto;
}

#submission-title {
  font-family: Beton-Open-Regular;
}

#submission-author {
  font-family: Junicode-Italic;
}

#submission-footnote {
  margin: 20px 0 20px 0;
  font-family: Junicode-Italic;
}

#navigation-link {
  font-family: Redaction-35;
  margin: 25px;
  padding: 10px;
  text-decoration: none;
  border: 2px solid transparent;
  color: black;
}

#navigation-link:hover {
  background-color: blue;
  color: white;
}

#title {
  margin-bottom: 20px;
}

#magazine-title {
  font-family: Redaction-35;
  margin: 0;
}

#magazine-subtitle {
  font-family: Junicode-Italic;
  margin: 0;
}

#submission-image-large {
  width: 70%;
  height: auto;
}

#submission-image-medium {
  width: 40%;
  height: auto;
}

#submission-image-small {
  width: 25%;
  height: auto;
}

#navigation-bar {
  margin-top: 40px;
}

img {
  margin: 10px;
}

div[id|="submission-text-"] {
  display: none;
}

/* Small devices (tablets, 768px and down) */
@media (max-width: 768px) {
  #about-text, #submissions-text {
    width: 100%;
  }
  #pages {
    margin-bottom: 20px; 
  }
}

/*collapsible text */
input[type='checkbox'] {
  display: none;
}

.wrap-collapsible {
  margin: 1.2rem 0;
}

div[id|="lbl-toggle-"] {
  color: red;
}

.lbl-toggle {
  display: block;
  font-weight: bold;
  font-family: Junicode-Italic;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
  color: black;
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
  color: blue;
}

.lbl-toggle::before {
  content: ' ';
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);
  transition: transform .2s ease-out;
}

.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.collapsible-content {
  max-height: 0px;
  overflow: scroll;
  transition: max-height .25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 350px;
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
  background: rgba(0, 105, 255, .2);
  border-bottom: 1px solid rgba(0, 105, 255, .45);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: .5rem 1rem;
}

.collapsible-content p {
  margin-bottom: 0;
}
